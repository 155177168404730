import { init as sentryInit, setTag as sentrySetTag } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import onClientReady from "./utils/onClientReady";

const debug = require("debug")("errorLogging");

import getReactAppKeys from '/imports/client/utils/getReactAppKeys';

//this will work when on production, as the static index file will response the cookie
//but when on development, it will not get when on first load
const clientKeys = getReactAppKeys();

const APP_URL = clientKeys.REACT_APP_URL || window.location.host;
debug("set sentry with public keys %o", clientKeys);
try {
  sentryInit({
    dsn: clientKeys.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    environment: clientKeys.REACT_APP_SERVER_NAME || window.location.host,
    release: clientKeys.REACT_APP_RELEASE_NAME,
    tracesSampleRate: 0.1
  });
  sentrySetTag("server", APP_URL);
} catch (e) {
  console.warn("sentry not setup correctly:", e.message);
}