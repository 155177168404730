import React from "react";
import ReactDOM from "react-dom";

// import "/imports/client/errorLogging";
// import "/imports/client/services/translations/i18n";

// import "/imports/startup/both";

// import AppRoutes from "/imports/client/router/AppRoutes";

import App from "/startup/App";



ReactDOM.render(<App />, document.getElementById("react-root"));
