import {lazy, Suspense} from 'react';

import "./main.less";

import "/imports/client/errorLogging";
import "/imports/client/services/translations/i18n";
import Loader from "/imports/client/components/utilities/Loader.jsx";

// import AppRoutes from "/imports/client/router/AppRoutes";
const AppRoutes = lazy(() => import("/imports/client/router/AppRoutes"));

function App() {

  return (
    <Suspense fallback={<Loader loading />}>
      <AppRoutes></AppRoutes>
    </Suspense>
  );

}

export default App;
